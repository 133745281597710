
import { getAPIResponse } from "../../core/http";
import { ChatMessage, Conversation } from "../../core/cosmos";
import { API_MESSAGES, SIZE_CONVERSATION } from "../../configuration/vortexConfig";

export class ChatController {
    generatePrompt(message: string) {
        const newMessage = {
            role: 'user',
            content: message
        }
        return  [
            ...API_MESSAGES,
            newMessage
        ]
        
    }

    async generateAnswer(bodyApiRequest: RequestOpenAI): Promise<any>{
        return new Promise ( async (resolve, reject) => {
            const response = await getAPIResponse(bodyApiRequest);
            let message: any[] = [];
            response.forEach((element: any) => {
                if (element.delta.content) {
                    if (element.delta.content === "Has sobrepasado el de tiempo de espera programado. Por favor, refresca tu navegador e intentalo de nuevo.") 
                        reject("Has sobrepasado el de tiempo de espera programado. Por favor, refresca tu navegador e intentalo de nuevo.");
                    message.push(element);
                }
                
            });
            resolve(message);
        });
    }

    addMessageToConversation(
        idUser: string,
        prevConversation: {
            prev: Conversation[],
            question: string, 
            apiResponse: string
    }): {
        createConversation: boolean, 
        conversation: Conversation | null
    } | null {
        const NumberMessages: number = prevConversation.prev?.length ? prevConversation.prev[0].messages.length : 0;
        

        const addMessage = (question: string, response: string): ChatMessage[] => {
            return [
                {
                "id": (NumberMessages + 1).toString(),
                "role": "user",
                "content": question,
                "date": (new Date()).toISOString()
            },{
                "id": (NumberMessages + 2).toString(),
                "role": "assistant",
                "content": response,
                "date": (new Date()).toISOString()
            }]
        }

        // Check spaces of messages in conversation
        switch( true ) {
            case NumberMessages === 0:
                // New conversation
                const CreateConversation: Conversation = {
                    "id": crypto.randomUUID(),
                    "user": idUser,
                    "messages": [...addMessage(prevConversation.question, prevConversation.apiResponse)],
                    "date": (new Date()).toISOString()
                }
                return {
                    createConversation: true,
                    conversation: CreateConversation
                };

            case NumberMessages + 2 > SIZE_CONVERSATION * 2:
                // New conversation
                const NewConversation: Conversation = {
                    "id": crypto.randomUUID(),
                    "user": idUser,
                    "messages": [...addMessage(prevConversation.question, prevConversation.apiResponse)],
                    "date": (new Date()).toISOString()
                }
                return {
                    createConversation: true,
                    conversation: NewConversation
                };

            default:
                // Add new messages
                const UpdateConversation: Conversation = {
                    "id": prevConversation.prev[0].id,
                    "user": prevConversation.prev[0].user,
                    "messages": [...prevConversation.prev[0].messages, ...addMessage(prevConversation.question, prevConversation.apiResponse)],
                    "date": prevConversation.prev[0].date
                }
                return {
                    createConversation: false,
                    conversation: UpdateConversation
                };
        }
    }
}


