/* index.ts */

export function parserMessage(chunkString: string): Promise<any> {
    return new Promise( (resolve) => {
        // Split chunk string in data blocks
        const chunks = chunkString.split('data: ');
        const chunkResponse: string | any[] = [];
        chunks.forEach( chunk => {
            if ( chunk && chunk.startsWith('{"id":') ){
                try {
                    const chunkObject = JSON.parse(chunk);
                    chunkResponse.push(chunkObject.choices[0])
                } catch {
                    // console.info('Bad Json')
                }
            }
        });
        resolve(chunkResponse);
    })
}

