/* header.tsx */

import styles from './header.module.scss';
import logoSeat from './../../assets/img/AF_LOGO_SEAT_SA_negativo_RGB.svg';
import logoHAI from './../../assets/img/Vortex_AI_Title_Blank.svg';
import confidential from './../../assets/img/Confidential.svg';

const HeaderComponent: React.FC<{userChatIdx: UserProfile | null}> = ({userChatIdx}) => {

    return (
        <div className={styles.header}>
            <div className={styles.headerLogoBlocksSeat}>
                <img src={logoSeat} className={styles.headerLogoSeat} alt="logo" />
                <img src={confidential} className={styles.headerLogoConfidential} alt="logo" />
            </div>
            <div className={styles.headerLogoBlocksVortex}>
                <img src={logoHAI} className={styles.headerLogoVortex} alt="logo" />
            </div>
            <div className={styles.headerLogoBlocksContext}>
                <div className={styles.headerUserInfo}>
                    { userChatIdx?.userPhoto && <img src={userChatIdx?.userPhoto} className={styles.headerUserInfoPhoto} alt="Foto de perfil" />}
                    {userChatIdx?.name}
                </div>
                <div className={styles.headerTitle}>
                    <div className={styles.headerTitleContext}>
                        Contexto Conectado:
                    </div>
                    <div className={styles.headerTitleDescription}>
                        {userChatIdx?.indexDescription}
                    </div>
                </div>

            </div>
        </div>
    )
}

export { HeaderComponent }