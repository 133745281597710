// userInfo/index.tsx

import { USER_GROUPS, AUTH_GROUP } from "../../configuration/vortexConfig";

export const getUserIndex = (parameters: { accessTokenResponse: any, localAccountId: string}): Promise<UserProfile | null> => {
    const { accessTokenResponse } = {...parameters};
    
    async function fetchDataFromStream(url: string, options: any): Promise<string> {
        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP ${response.status}: ${await response.text()}`);
            }

            // Lee el flujo de datos como texto
            const textData = await response.text();
            return textData;
        } catch (error) {
            console.error("Error al obtener los datos del flujo:", error);
            return ""; // Devuelve una cadena vacía en caso de error
        }
    }

    function getIndex(message: MSGraphsGroups): { authorizedUser: boolean, userIndex: string | null, indexDescription: string | null } {
        let authorizedUser: boolean = false;
        let userIndex: string | null = null;
        let indexDescription: string | null = null
        message.value.forEach((group: MSGraphsGroupsValue) => {
            // Check if user belong to auth users group
            if ( group.displayName === AUTH_GROUP ) authorizedUser = true;
            let allowGroup: boolean  = false;
            if (group.displayName) {
                allowGroup = group.displayName in USER_GROUPS;
                if ( allowGroup && !userIndex) {
                   userIndex = USER_GROUPS[group.displayName];
                   indexDescription = group.description
                }
            }
        });
        return {
            authorizedUser,
            userIndex,
            indexDescription
        }
    }

    async function getPhoto(url: string, options: any){
        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP ${response.status}: ${await response.text()}`);
            }

            // Lee el flujo de datos como imagen
            const image = await response.blob();
            if (image !== null) {
                window.URL = window.URL || window.webkitURL;
                return window.URL.createObjectURL(image);
            }
        } catch (error) {
            console.error("Error al obtener los datos del flujo:", error);
            return ""; // Devuelve una cadena vacía en caso de error
        }
    }

    return new Promise( (resolve) => {
        const headers = new Headers();
        const bearer = "Bearer " + accessTokenResponse.accessToken;

        headers.append("Authorization", bearer);
        headers.append("ConsistencyLevel", "eventual" )

        var options = {
                method: "GET",
                headers: headers
        };
        
        // Filter groups. DisplayName contains vortex and limit result is 999
        fetchDataFromStream(`https://graph.microsoft.com/v1.0/me/memberOf?$search="displayName:vortex"&$top=999`, options)
            .then(async (decodedMessage) => {
                const userPhoto = await getPhoto(`https://graph.microsoft.com/v1.0/me/photos/48x48/$value`, options)
                const { authorizedUser, userIndex, indexDescription } = {  ...getIndex(JSON.parse(decodedMessage)) }
                resolve({
                    "authorizedUser": authorizedUser,
                    "name": accessTokenResponse.account.name,
                    "username": accessTokenResponse.account.username,
                    "index": userIndex,
                    "indexDescription": indexDescription,
                    "userPhoto": userPhoto
                });
            })
            .catch((error) => {
                console.error("Error general:", error);
                resolve(null)
            });
    })
}
