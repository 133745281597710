import { CosmosClient } from "@azure/cosmos";

import { COSMOS_ENDPOINT, COSMOS_KEY, COSMOS_CONTAINER, COSMOS_DB } from "../../configuration/vortexConfig";
import { Conversation  } from "./models";
export class CosmosConn {

    mongoClient;

    constructor() {
        const options = {
            endpoint: COSMOS_ENDPOINT,
            key: COSMOS_KEY,
            userAgentSuffix: 'CosmosDBVortexAI'
        };
        this.mongoClient = new CosmosClient(options)
    }


    private createNewUser(conversation: Conversation): Promise<any>{
        return new Promise( async(resolve) => {
            const newUser: {userId: string, conversation: Conversation[]} = {
                "userId": conversation.user,
                "conversation": [conversation]
            }

            await this.mongoClient
                .database(COSMOS_DB)
                .container(COSMOS_CONTAINER)
                .items.upsert(newUser)
            resolve(true);
        });
    }

    fetchChatHistoryInit(idUser: string): Promise<any> {
        return new Promise( async(resolve: any) => {
            const querySpec = {
                query: 'SELECT VALUE r FROM root r WHERE r.userId = @idUser',
                parameters: [
                {
                    name: '@idUser',
                    value: idUser
                }
                ]
            }

           
            const { resources: results } = await this.mongoClient
                .database(COSMOS_DB)
                .container(COSMOS_CONTAINER)
                .items.query(querySpec)
                .fetchAll()
            // Check if has historic
            if ( results.length ) {
                for (var queryResult of results) {
                    resolve(queryResult);
                }
            } else {
                resolve(null)
            }
        })
    }

    addConversation(createConversation: boolean | undefined, conversation: Conversation | null): Promise<boolean> {
        return new Promise( async(resolve: any) => {
            if ( conversation ) {
                this.fetchChatHistoryInit(conversation.user)
                    .then( async (currHistory: any) => {
                        
                        // Add new conversation to historic
                        if ( createConversation ) 
                            if ( currHistory ) {
                                currHistory['conversation'].unshift(conversation);

                                // Update mongodb
                                await this.mongoClient
                                    .database(COSMOS_DB)
                                    .container(COSMOS_CONTAINER)
                                    .item(currHistory.id)
                                    .replace(currHistory);

                                resolve(true);
                            } else {
                                // Not have previous conversation
                                await this.createNewUser(conversation);
                                resolve(true);
                            }
                        else {
                            // Add message to conversation
                            currHistory['conversation'][0] = conversation;

                            await this.mongoClient
                                .database(COSMOS_DB)
                                .container(COSMOS_CONTAINER)
                                .item(currHistory.id)
                                .replace(currHistory);

                            resolve(false)
                        }
                    });
            } else {
                resolve(null);
            }
            
        })
    }
}