// chat.tsx

import styles from './chat.module.scss';
import Logout from '@mui/icons-material/Logout';

import { useEffect, useState } from 'react';
import { InitalChatRequest, ChatUserContext } from '../../core/chatContext';

import { HeaderComponent } from '../../components/header/header';
import { HistoryComponent } from '../../components/history/history';
import { ChatComponent } from '../../components/chat/chat';

import { NOT_AUTH_USER } from '../../configuration/vortexConfig';
import { useMsal } from '@azure/msal-react';

const ChatPage: React.FC<{userId: string, userChatIdx: UserProfile | null}> = ({userId, userChatIdx}) => {

    const [currChat, setCurrChat] = useState(InitalChatRequest);
    const [currHistory, setCurrHistory] = useState([]);
    const [userAsks, setUserAsks] = useState([] as Array<string>);
    const [iaAnswers, setIaAnswers] = useState([] as Array<string>);

    useEffect( () => {
        
        // Add user index to request parameters to call openai api
        setCurrChat( (currChat: RequestOpenAI) => {
            if ( userChatIdx?.index) {
                currChat.data_sources[0].parameters.index_name = userChatIdx.index;
            }
            return currChat;
        })
    },[userChatIdx]);
   
    const NotAutorizedComponent = () => {
        const { instance } = useMsal();
        
        const handleLogoutRedirect = () => {
            instance.logoutRedirect().catch((error) => console.log(error));
        };

        return (
            <div className={styles.chatError}>
                <div className={styles.chatErrorMessage}>{NOT_AUTH_USER}</div>
                <div className={styles.chatErrorLogOut} onClick={handleLogoutRedirect} role="button">
                    <Logout></Logout>
                    <span>Log Out</span>
                </div>
            </div>
        )
    }

    return(
        <div className={styles.chat}>
            <HeaderComponent userChatIdx={userChatIdx}></HeaderComponent>  
            { ( userChatIdx?.authorizedUser && userChatIdx?.index ) ?  <ChatUserContext.Provider value={{currChat, setCurrChat, currHistory, setCurrHistory, userAsks, setUserAsks, iaAnswers, setIaAnswers}}>
                <div className={styles.chatContainer}>
                    <HistoryComponent idUser={userId}/>        
                    <div className={styles.chatContainer}>
                        <ChatComponent idUser={userId} userPhoto={userChatIdx?.userPhoto}/>
                    </div>
                </div>
            </ChatUserContext.Provider> : <NotAutorizedComponent/>} 
        </div>
    )

}

export { ChatPage }