/* index.tsx */

export function formatHtml(body: string): string {
    // Remove docs.
    body = body.replace(/\[doc\d+\]/g, "");

    return body;
}



