// history.scss

import styles from './history.module.scss';
import Menu from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';
import Logout from '@mui/icons-material/Logout';

import { useMsal } from '@azure/msal-react';
import { useContext, useEffect } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { CosmosConn } from '../../core/cosmos/api';
import { Conversation, ChatMessage } from '../../core/cosmos';
import { User } from './iHistory';
import { ChatUserContext } from '../../core/chatContext';

const LogOutElement = () => {
    const { instance } = useMsal();

    const handleLogoutRedirect = () => {
        instance.logoutRedirect().catch((error) => console.log(error));
    };

    return (
        <div className={styles.historyLogout}>
            <div className={styles.historyLogoutContainer} onClick={handleLogoutRedirect} role="button">
                <Logout></Logout>
                <span>Log Out</span>
            </div>
        </div>
    )
}

const HeaderElement = () => {
    const toogleMenu = () => {

        // Show / Hide icon
        if ( window.screen.width < 1366) {
            const IconMenuElement = document.querySelector<HTMLElement>(`.${styles.historyIconMenu}`);
            if ( IconMenuElement ) IconMenuElement.style.display = IconMenuElement?.style.display === 'none' || '' ? 'block' : 'none';
            const IconCloseElement = document.querySelector<HTMLElement>(`.${styles.historyIconClose}`);
            if ( IconCloseElement ) IconCloseElement.style.display = IconCloseElement?.style.display === 'block'  ? 'none' : 'block';
            const LogOutElement = document.querySelector<HTMLElement>(`.${styles.historyLogout}`);
            if ( LogOutElement ) LogOutElement.style.display = LogOutElement?.style.display === 'block'  ? 'none' : 'block';
        } else {
            const IconMenuElement = document.querySelector<HTMLElement>(`.${styles.historyIconMenu}`);
            if ( IconMenuElement ) IconMenuElement.style.display = IconMenuElement?.style.display === 'block' || '' ? 'none' : 'block';
            const IconCloseElement = document.querySelector<HTMLElement>(`.${styles.historyIconClose}`);
            if ( IconCloseElement ) IconCloseElement.style.display = IconCloseElement?.style.display === 'none' ? 'block' : 'none';
            const LogOutElement = document.querySelector<HTMLElement>(`.${styles.historyLogout}`);
            if ( LogOutElement ) LogOutElement.style.display = LogOutElement?.style.display === 'none'  ? 'block' : 'none';
        }

        const HistoryContainerElement = document.querySelector(`.${styles.history}`);
        HistoryContainerElement?.classList.toggle(`${styles.historyIsActive}`);

        const ConversationElement = document.querySelector(`.${styles.historyConversations}`);
        ConversationElement?.classList.toggle(`${styles.historyConversationsIsActive}`);


    }

    return(
        <div className={styles.historyIcon} onClick={toogleMenu} role="button">
            <div className={styles.historyIconMenu}>
                <Menu style={{fill: "#2c2a29"}}></Menu>
            </div>
            <div className={styles.historyIconClose}>
                <Close style={{fill: "#2c2a29"}}></Close>
            </div>
        </div>
    )
}

const ConversationsElement: React.FC<{ userConversation: Conversation[] }> = ({ userConversation }) => {

    const currChatContent: any = useContext(ChatUserContext);

    const selectConversation = (conversation: Conversation, conversationIdx: number) => {

        // Create messages context
        const messages: {role: string, content: string}[] = [currChatContent.currChat.messages[0]];

        for ( let idx = conversationIdx ; idx < conversation.messages.length; idx++) {

            if ( conversation.messages[idx].role === 'user') currChatContent.setUserAsks( (prevArray: any) => [...prevArray, conversation.messages[idx].content]);
            if ( conversation.messages[idx].role === 'assistant') currChatContent.setIaAnswers( (prevArray: any) => [...prevArray, conversation.messages[idx].content]);
            
            // Add history messages to current context
            if ( messages ) messages.push(
                {
                    role: conversation.messages[idx].role,
                    content:  conversation.messages[idx].content
                })
        }

        // Update context with new data
        if ( messages.length ) {
            currChatContent.setCurrChat( (currChat: any) => {
                currChat.messages = messages;
                return currChat;
            });
        }
    }

    return(
        <>
            <div className={styles.historyConversations}>
                <div className={styles.historyConversationsTitle}>Historial Conversaciones</div>
                {
                    userConversation?.length 
                        ? (
                            userConversation.map((conversation: Conversation, index: number) => (  
                                <Fragment key={index}>
                                    <div className={styles.historyConversationsSubtitle}>{(new Date(conversation.date)).toLocaleDateString('es-ES', 
                                        {   year: "numeric",
                                            month: "long",
                                            day: "numeric"
                                        })}</div>  
                                    {
                                        conversation.messages.map( (message: ChatMessage, index: number) => {
                                            if ( message.role === 'user') return ( 
                                                <div key={index} className={styles.historyConversationsQuestion} onClick={() => {
                                                    selectConversation(conversation, index)
                                                }} role="button">
                                                    {message.content}
                                                </div>
                                            )
                                            return null
                                        })
                                    }
                                </Fragment>
                            ))
                        )
                        : null
                }
            </div>
            <LogOutElement/>
        </>
    )
}

const HistoryComponent: React.FC<User> = ({ idUser }) => {

    const currChatContent: any = useContext(ChatUserContext);

    const Cosmos_Connection = new CosmosConn();

    useEffect(() => {
        if ( idUser ) {
            // Get user history and to context
            Cosmos_Connection.fetchChatHistoryInit(idUser)
                .then( mongoResponse => {
                    currChatContent.setCurrHistory(mongoResponse?.conversation ||  []);
                })
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        idUser
    ])

    return(
        <div className={styles.history}>
            <HeaderElement/>
            <ConversationsElement userConversation={currChatContent.currHistory}/>
        </div>
        
    )
}

export { HistoryComponent}