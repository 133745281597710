// chat.tsx
import { useState, useContext, createRef, useEffect } from 'react';

import { ChatUserContext } from '../../core/chatContext';
import { ChatController } from './chat.controller';
import { CosmosConn } from '../../core/cosmos/api';

import { DialogComponent } from '../dialog/dialog';

import styles from './chat.module.scss';
import logo from '../../assets/img/AF_LOGO_SEAT_SA_RGB.svg';
import Send from '@mui/icons-material/Send';

import { API_DATA_SOURCES, INITIAL_CONTEXT_MESSAGE, DISCLAIMER, VERSION } from '../../configuration/vortexConfig';

const ChatComponent: React.FC<{idUser: any, userPhoto: any}> = ({idUser, userPhoto}) => {

    const Chat_Controller = new ChatController();
    const Cosmo_Conn = new CosmosConn();

    const currChatContent: any = useContext(ChatUserContext);
    const textAreaRef= createRef<HTMLTextAreaElement>();

    useEffect(() => {
        // Focus in textarea when render page
        textAreaRef.current?.focus();
    });

    const WelcomeComponent = () => {
        return (
            <div className={styles.welcomeComponent}>
                <img src={logo} className={styles.welcomeComponentLogo} alt="logo" />
            </div>
        )
    }

    const PromptComponent = () => {
        const [ formData, setFormData] = useState({question:""});

        const handleTextareaKeyPress = (e: any) => {
            if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
            }
        };

        const handleSubmit = (e: any) => {
            e.preventDefault();

            if ( formData.question) {
                // Add new question to array
                currChatContent['setCurrChat']( (prevContext: RequestOpenAI) => {
                    prevContext.messages = [...prevContext.messages, {  
                        "role": "user", 
                        "content": formData.question }
                    ]
                    return prevContext
                });

                // Update user question
                currChatContent.setUserAsks( (prevArray: any) => [...prevArray, formData.question]);

                // Add loading message
                currChatContent.setIaAnswers( (prevArray: any) => [...prevArray, 'Generando respuesta...']);

                // Update response of ia
                Chat_Controller.generateAnswer(currChatContent['currChat'])
                    .then( response => {
                        let apiResponse = '';
                        const NumberChunks = response.length;
                        response.forEach( ( chunk: any, idx: number)  => { 
                            setTimeout( () => {
                                apiResponse += response[idx].delta.content
                                currChatContent.setIaAnswers((prevArray: string[] ) => [...prevArray.slice(0, prevArray.length - 1), apiResponse]);
                                // Add response to context at last entry
                                if ( idx === NumberChunks -1 ) {

                                    // Add api response to history context and save @ cosmodb
                                    currChatContent.setCurrHistory( (curr: any) => {
                                        const {createConversation, conversation} = {...Chat_Controller.addMessageToConversation(
                                            idUser,
                                            {
                                                prev: curr,
                                                question: formData.question,
                                                apiResponse: apiResponse
                                            }
                                        )};

                                        // Save conversation @ cosmodb
                                        if ( conversation ) (async()=> {
                                            await Cosmo_Conn.addConversation(createConversation, conversation);
                                        })();

                                        if ( createConversation ) {
                                            // Add api response to chat context
                                            currChatContent.setCurrChat( (prevContext: RequestOpenAI) => {
                                                prevContext.messages = [...prevContext.messages, {  
                                                    "role": "assistant", 
                                                    "content": apiResponse }
                                                ]
                                                return prevContext
                                            })
                                            return [conversation, ...curr]
                                        } else {
                                            // Update chat context
                                            // If it is last message of context create new context.
                                            currChatContent.setCurrChat( (prevContext: RequestOpenAI) => {
                                                if ( prevContext.messages.length === API_DATA_SOURCES.parameters.top_n_documents ) {
                                                    prevContext.messages = INITIAL_CONTEXT_MESSAGE;  // Initialize messages
                                                    return prevContext
                                                } else {
                                                    prevContext.messages = [...prevContext.messages, {  
                                                        "role": "assistant", 
                                                        "content": apiResponse }
                                                    ]
                                                    return prevContext
                                                }
                                            })
                                            return [conversation, ...curr.slice(1,)]
                                        }                                   
                                    });                                  
                                }
                            }, idx * 50);
                        });
                    })
                    .catch( response => {
                        currChatContent.setIaAnswers((prevArray: string[] ) => [...prevArray.slice(0, prevArray.length - 1), response]);
                    });
            }
        }

        return (
            <div className={styles.promptComponent}>
                <form className={styles.promptComponentInput} onSubmit={handleSubmit}>
                    <div className={styles.promptComponentInputTextareaContainer}>
                        <textarea 
                            ref={textAreaRef}
                            className={styles.promptComponentInputTextarea} 
                            onChange={(e) => setFormData({...formData, question: e.target.value})}
                            onKeyDown={handleTextareaKeyPress} // Handles the "Enter" key
                            value={formData.question} 
                            id="body"
                            name="body" 
                            placeholder='En que te puedo ayudar'></textarea>
                    </div>
                    <button className={styles.promptComponentButton} type='submit'>
                        <Send className={styles.promptComponentIcon}></Send>
                    </button>
                </form>
            </div>
        )
    }

    const showMainComponent = () => {
        if ( currChatContent.userAsks.length === 0 ) {
            return (
                <WelcomeComponent/>
            )
        } else {
            return (
                <DialogComponent userPhoto={userPhoto} userAsks={currChatContent.userAsks} iaAnswers={currChatContent.iaAnswers}></DialogComponent>
            )
        }
    }

    const FooterComponent = () => {
        return (
            <>
                <div className={styles.disclaimerComponent}>
                    {DISCLAIMER}
                </div>
                <div className={styles.versionComponent}>
                    {VERSION}
                </div>
            </>
        )
    }
    return(
        <div className={styles.chatComponent}>
            <>
                {showMainComponent()}
                <PromptComponent />
                <FooterComponent />
            </>
        </div>
    )
}

export { ChatComponent }

